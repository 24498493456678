
// ------------- AUTO-GENERATED WITH '/src/environments/set-env.ts' -------------
import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  enableProdMode: true,
  appVersion: 'cde98a3',
  isSiCountryEnabled: true,
  isEmailRegistrationEnabled: true,
  isStoreDevToolsEnabled: true,
  isStoreLogEnabled: true,
  isDebugEnabled: true,
  isTesting: true,
  refreshAccessTokenIntervalSec: 3600,
  api: 'https://surveys-testing.pollpass.com',
  socket: 'wss://neo-testing.pollpass.com',
  authClientId: '1PlWXn3VKdpMu0M14OYhQTCeSM2y3Aq8awDseo42QlINHBtpM6e8wHf8fabkRGJA',
  authClientSecret: 'Cjt3yecyT5XyMC9onGHeRhkgF7f1pGMzXyqMenKUZZXgpRZFseo36NliFgZBA2bX',
  bugsnagKey: '1dc103023788ce5cfe226ec50451d717',
  bugsnagStage: 'testing',
  segmentKey: 'DWwxokmJwIUvzdM0IOqFi3AokL1MGPgV',
  VWOAccountId: 'test',
  eKey: 'ec6d29c44f7c19172afe9b356c58dcfec',
  hotjarKey: '2738532',
};
